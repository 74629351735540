export const buildMeta = (data, { lang = '', languages = [] } = {}) => ({
    title: data.title,
    titleTemplate: (title) => (title ? `${title}${data.titleSeparator}${data.titleDefault}` : data.titleDefault),
    meta: [
        // Title
        { property: 'og:title', content: data.title || data.titleDefault },
        { name: 'twitter:title', content: data.title || data.titleDefault },
        { itemprop: 'name', content: data.title || data.titleDefault },

        // Description
        { name: 'description', content: data.description || data.descriptionDefault },
        { name: 'keywords', content: data.keywords || data.keywordsDefault },
        { property: 'og:description', content: data.description || data.descriptionDefault },
        { name: 'twitter:description', content: data.description || data.descriptionDefault },
        { itemprop: 'description', content: data.description || data.descriptionDefault },

        // Image
        { property: 'og:image', content: data.image || data.imageDefault },
        { name: 'twitter:image:src', content: data.image || data.imageDefault },
        { itemprop: 'image', content: data.image || data.imageDefault },

        // Sitename
        { property: 'og:site_name', content: data.titleDefault },

        // Url
        { property: 'og:url', content: window.location.href },
        { name: 'twitter:site', content: window.location.href },

        // Others
        { name: 'og:locale', content: lang },
        { name: 'twitter:card', content: 'summary' },
        { name: 'author', content: 'Project Five' },
    ],
    link: [
        { rel: 'canonical', href: window.location.href },
        ...(languages.length > 1
            ? languages
                .filter((it) => it.id !== lang)
                .map((alternatelang) => ({
                    rel: 'alternate',
                    hreflang: alternatelang.id,
                    href: window.location.href.replace(`/${lang}/`, `/${alternatelang.id}/`),
                }))
            : []),
    ],
    htmlAttrs: {
        lang,
    },
    script: [
        ...(data.schemas && data.schemas.length
            ? data.schemas.map((schema) => ({
                type: 'application/ld+json',
                json: schema,
            }))
            : []),
    ],
});

const getSchemas = ({ statedata, schemas = [] }) => {
    if (schemas.length) {
        if (schemas.find((it) => it['@type'] === 'Organization')) {
            return schemas;
        }
    }
    if (Array.isArray(statedata.schemas)) {
        const newSchemas = statedata.schemas.filter((it) => it['@type'] === 'Organization');
        newSchemas.push(...schemas);
        return newSchemas;
    }
    return schemas;
};

export const setMeta = ({
    from, data, schemas = [], statedata,
}) => {
    let newMeta = false;
    const newState = JSON.parse(JSON.stringify(statedata));

    if (from === 'router') {
        if (data.meta && data.meta.meta === false) {
            return false;
        }
        if (data.meta && typeof data.meta.meta !== 'undefined') {
            newMeta = JSON.parse(JSON.stringify(data.meta.meta));
        }
    } else if (data) {
        newMeta = data;
    }
    if (!newMeta || typeof newMeta !== 'object') {
        // reset
        newMeta = {
            title: '',
            description: '',
            image: '',
            keywords: '',
        };
    }

    Object.assign(newState, newMeta);
    Object.entries(newState).forEach(([key, value]) => {
        if (typeof value === 'function') {
            newState[key] = value();
        }
    });

    if (Array.isArray(statedata.schemas)) {
        newState.schemas = getSchemas({ statedata, schemas });
    }
    return newState;
};

export default { buildMeta };
