<template functional>
    <div class="buttons" :class="data.staticClass">
        <Button
            v-for="action in props.actions"
            :key="'action' + action.title"
            :class="action.class"
            :to="action.to"
            :href="action.href"
            v-bind="action"
        >
            {{ action.title }}
        </Button>
    </div>
</template>

<script>
export default {
    name: 'Actions',
    props: {
        actions: Array,
    },
};
</script>
